import React from "react";
import axios from "axios";
// import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'

// const HashTest = () => {
//     return (  
//         <div>
//             <Post url="apitest.myscopetech.io/account/getToken" params={{userName: "dcrowell@roofscope.com", accessPass: "Dhalgren17"}}>
//                 {(error, response, isLoading, makeRequest, axios) => {
//                     if(error) {
//                     return (<div>Something bad happened: {error.message} <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button></div>)
//                     }
//                     else if(isLoading) {
//                     return (<div>Loading...</div>)
//                     }
//                     else if(response !== null) {
//                         console.log(response.status);
//                         console.log(response.statusText);
//                         console.log(response.message);
//                         console.log(response.token);
//                         console.log(response);
//                     return (<div>{response.data.message} <button onClick={() => makeRequest({ params: { refresh: true } })}>Refresh</button></div>)
//                     }
//                     return (<div>Default message before request is made.</div>)
//                 }}
//             </Post>
//         </div>
//     )
//   }
  
// export default HashTest
let data = {};
data.userName = "dcrowell@roofscope.com";
data.accessPass = "Dhalgren17";
let sendData = JSON.stringify(data);

// const baseURL = "http://apitest.myscopetech.io/account/getToken";

// const config = {
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded',
//       'x-apikey': '7SGDyGGiv861S3dJMzLJ49JB3ag9Q64nm6dqIFZ1',
//       "Access-Control-Allow-Origin": "*"
//     }
// }

  var config = {
    method: 'post',
    url: ' http://apitest.myscopetech.io/account/getToken',
    headers: { 
      'x-api-key': '7SGDyGGiv861S3dJMzLJ49JB3ag9Q64nm6dqIFZ1',
      'Content-Type': 'application/x-www-form-urlencoded',
      "Access-Control-Allow-Origin": "*",
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
    },
    data : data
  };

export default function HashTest(){
    function checkLogin() {
        // axios
        //   .post(baseURL, { json: sendData } , config)
        //   .then((response) => {
        //     console.log(response.data);
        //   });
        axios(config)
            .then(function (response) {
            console.log(JSON.stringify(response.data));
        }).catch(function (error) {
            console.log(error);
        });
      }
    return (
        <div>
            <button onClick={checkLogin}>Create Post</button>
        </div>
      );
}